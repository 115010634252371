import React, { useEffect } from "react"
import { graphql } from "gatsby"

import "../theme/app.scss"
import "./index.scss"
import DriveEngagement from "../images/drive-engagement.svg"
import PeakPerformance from "../images/peak-performance.svg"
import UnderstandTeam from "../images/understand-team.svg"
import HealthWork from "../images/health-work.svg"
import SupportEngineers from "../images/support-engineers.svg"
import HighlighHeatmap from "../images/highlight-heatmap.svg"
import ExpertGuidance from "../images/expert-guidance.svg"

import Slider from "react-slick"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"
import Cover from "../images/cover-home.svg"
import Onboard from "../images/home-onboard.svg"
import Survey from "../images/home-survey.svg"
import Action from "../images/home-action.svg"
import Iso from "../images/home-iso.svg"
import Global from "../images/home-global.svg"
import Anonymity from "../images/home-anonymity.svg"
import VerticalCarousel from "../components/vertical-carousel/vertical-carousel"

const IndexPage = ({ data }) => {
  const posts = (data && data.blogPosts && data.blogPosts.edges) || []

  useEffect(() => {
    // document.getElementById("home-1").addEventListener("wheel", function(e) {
    //   snapScrollCustom(e, 1)
    // })
    document.getElementById("home-2").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 2)
    })
    document.getElementById("home-3").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 3)
    })
    document.getElementById("home-4").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 4)
    })
    document.getElementById("home-5").addEventListener("wheel", function(e) {
      snapScrollCustom(e, 5)
    })
  }, [])

  const carouselDataHome = [
    {
      title: "Peak performance",
      desc:
        "Accuracy brings trust. Higher scores reflect genuinely better work productivity for you and your teams.",
      contentImg: PeakPerformance,
      color: "dark-blue",
    },
    {
      title: "Understand your team",
      desc:
        "Build your network in minutes and discover the real challenges that need attention.",
      contentImg: UnderstandTeam,
      color: "teal",
    },
    {
      title: "Drive engagement",
      desc:
        "Get support in sending timely messages to take part. Automatically receive pre-written messages you can customise and send on.",
      contentImg: DriveEngagement,
      color: "purple",
    },
    {
      title: "Health at work",
      desc:
        "Link physical and mental health directly to work performance. See the impact at personal, team and company levels.",
      contentImg: HealthWork,
      color: "green",
    },
    {
      title: "Support your managers",
      desc:
        "Every team is different. Managers get custom team reports to remove the guesswork, help prioritise action and grow their own skills.​",
      contentImg: SupportEngineers,
      color: "teal",
    },
    {
      title: "Highlights & Heatmaps",
      desc:
        "Powerful interactive dashboards pinpoint issues and facilitate precision, priority and progression.​",
      contentImg: HighlighHeatmap,
      color: "dark-blue",
    },
    {
      title: "Get expert guidance​",
      desc:
        "Change what matters to you with SHAPE’s library of training tools: personalised short reads, factsheets, and best practice worksheets to guide everyone, including the executive team​.",
      contentImg: ExpertGuidance,
      color: "purple",
    },
  ]

  const snapScrollCustom = (e, currentIndex) => {
    e.preventDefault()
    let wheelMetrics = e.deltaY
    let nextSection = null
    if (wheelMetrics > 1) {
      //next section
      nextSection = currentIndex < 6 ? currentIndex + 1 : null
    } else if (wheelMetrics < -1) {
      //previous section
      nextSection = currentIndex > 1 ? currentIndex - 1 : null
    }
    if (nextSection === null) {
      return
    }
    let nextSectionObj = document.getElementById(`home-${nextSection}`)
    if (nextSectionObj) {
      nextSectionObj.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    }
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    autoplay: true,
    speed: 200,
    autoplaySpeed: 1500,
    cssEase: "linear",
    pauseOnHover: false,
  }

  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <div className="home-container fadeIn" id="snap-container">
        <section className="section-top">
          <div className="home-title" id="home-1">
            <Slider {...settings}>
              <div className="home-title-text">
                <span>Productive</span> People
              </div>

              <div className="home-title-text">
                We measure <span>Productivity</span>
              </div>

              <div className="home-title-text">
                Get <span>Productivity</span>
              </div>

              {/* <div className="home-title-text">
                Got <span>Productivity</span>?
              </div>

              <div className="home-title-text">
                Love <span>Productivity</span>
              </div>

              <div className="home-title-text">
                Measure your <span>Productivity</span>
              </div> */}
            </Slider>
          </div>
          <div className="home-desc">
            One platform to help your employees perform measurably better.
            Transform productivity together.
          </div>
          <div className="home-btn-flex">
            <a className="home-btn-primary" href="/launch">
              Start now
            </a>
            <button
              onClick={() => {
                document
                  .getElementById("how-shape-works")
                  .scrollIntoView({ behavior: "smooth" })
              }}
              className="home-btn-secondary"
            >
              Learn more
            </button>
          </div>
          <div
            className="home-cover-img-parent"
            id="home-2"
            // onWheel={e => snapScrollCustom(e, 2)}
          >
            <div className="home-cover-img">
              <img src={Cover} alt="main-cover-img" />
            </div>
          </div>
        </section>
        <section
          id="home-3"
          // onWheel={e => snapScrollCustom(e, 3)}
        >
          <div className="home-cover-desc">
            Every business wants <span>high performance people</span>. They look
            and act a certain way. So do their employers.
            <p>
              We’ve cracked the code that allows employees to reach their full
              potential.
            </p>
            <a href="/purpose">Read why</a>
          </div>
        </section>
        <section
          id="home-4"
          // onWheel={e => snapScrollCustom(e, 4)}
        >
          <div className="home-shapeworks-parent">
            <div id="how-shape-works" className="home-shapeworks-title">
              How SHAPE works
            </div>
            <div className="home-shapeworks-card-parent">
              <div className="home-shapeworks-card">
                <img
                  className="home-shapeworks-img"
                  src={Onboard}
                  alt="home-onboard"
                />
                <div className="home-shapeworks-title">1. Onboard</div>
                <div className="home-shapeworks-desc">
                  Just 10 minutes to onboard your company, no matter your size​
                </div>
              </div>
              <div className="home-shapeworks-card">
                <img
                  className="home-shapeworks-img"
                  src={Survey}
                  alt="home-survey"
                />
                <div className="home-shapeworks-title">2. Survey</div>
                <div className="home-shapeworks-desc">
                  Everyone measures 12 areas of employee productivity​
                </div>
              </div>
              <div className="home-shapeworks-card">
                <img
                  className="home-shapeworks-img"
                  src={Action}
                  alt="home-action"
                />
                <div className="home-shapeworks-title">3. Action</div>
                <div className="home-shapeworks-desc">
                  Reports for all employees, managers and executive
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="home-5"
          // onWheel={e => snapScrollCustom(e, 5)}
        >
          <div className="home-shapeforyou-parent">
            <div className="home-shapeforyou-title">SHAPE for you</div>
            <VerticalCarousel data={carouselDataHome} />
          </div>
        </section>
      </div>

      <div id="home-6" className="home-shapeforyou-card-parent">
        <div className="home-shapeforyou-card">
          <img className="home-shapeforyou-card-img" src={Iso} />
          <div className="home-shapeforyou-card-title">ISO Power</div>
          <div className="home-shapeforyou-card-desc">
            You can trust us, we're a safe choice. We're ISO9001 Quality and
            ISO27001 Information Security certified.
          </div>
        </div>
        <div className="home-shapeforyou-card">
          <img className="home-shapeforyou-card-img" src={Global} />
          <div className="home-shapeforyou-card-title">Global reach</div>
          <div className="home-shapeforyou-card-desc">
            The single platform you need to measure across all global sites.
            Standardise and succeed.
          </div>
        </div>
        <div className="home-shapeforyou-card">
          <img className="home-shapeforyou-card-img" src={Anonymity} />
          <div className="home-shapeforyou-card-title">Anonymity</div>
          <div className="home-shapeforyou-card-desc">
            Personal, confidential and secure. Everyone's identity is protected
            through aggregated reporting.
          </div>
        </div>
      </div>

      <CTA isFreeReport={true} />
    </Layout>
    // <div className="redirect-page-temporary">
    //   <img src={Logo} alt="shape-logo" />
    //   <div>
    //     Please visit <a href="https://mysha.pe">https://mysha.pe.</a>
    //   </div>
    // </div>
  )
}

export default IndexPage

export const landingBlogQuery = graphql`
  query landingBlogQuery {
    blogPosts: allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 3
      filter: { isFeatured: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...BlogPreviewFields
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
